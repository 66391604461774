import React, { useState,useEffect } from 'react';
import error from '../../assets/img/error.png';
import './index.scss';




function NoPage(props) {
    useEffect(()=>{

        let el1 =  document.getElementById("header1");
        let logo = document.getElementById("logonow" );
        let calls = document.getElementById("calls" );
        let book = document.getElementById("booknow" );
        let icons = [document.getElementById("scroll1"),document.getElementById("scroll2")]
       el1.classList.remove("in-viewport");
       
       el1.classList.add("out-viewport");
       calls.classList.remove("d-flex");
       el1.classList.add("animate__fadeInDown");
       calls.style.display = "none";
       icons[0].style.display = "none";
       icons[1].style.display = "block";
       book.style.display = "block";
       logo.style.display = "block";
       
       

    },[])

    return (
        <div className='m-auto p-0 w-90 hw-100 vancacies'>
            <div className="position-relative top-20 error_img text-center ">
                <img className="m-auto" src={error} />
            </div>
            <div className='w-auto position-relative error_msg text-center hover m-0 p-3 border rounded text-danger display-6'>
                We couldn't find the page you're looking for.. <br />Sorry
            </div></div >
    )
}

export default NoPage