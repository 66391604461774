import SectionTitle from '../../components/SectionTitle';
import './index.scss'
import { useInView } from 'react-intersection-observer';
import React, { useEffect, useRef, useState, useMemo } from 'react';



function FindUs() {

    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    const [onScroll, setOnScroll] = React.useState('hidden');

    useEffect(() => {

        if (inView) {
            setOnScroll('show');
        }
    }, [inView]);


    return <>
        <section id="Contact">


            <div className={`container-fluid mx-0 my-3 py-3 ${onScroll} mid-animation down-off-screen blurry`} ref={ref}>
                <SectionTitle name={"Find Us"} />
                <div className='container card my-2 py-2 px-3 contact-container text-left shadow left-border mid-animation hover-zoom-2' >

                    <div className='contact-1'>
                    <h3 className='heading-span'>
                        Working Hours
                    </h3>
                    <hr className='bg-light w-50 text-left mx-0 p-0 my-0'></hr>
                    <div className='my-2'>
                        <p className='text-secondary '>
                            <b>Monday-Friday :</b> 09:30H to 17:30H <br />
                            <b>Holidays :</b > <span className="text-danger"><b>closed </b></span><br />
                            <b>Saturday :</b> 09:30H to 17:30H <br />
                            <b>Sunday :</b>  <span className="text-danger"><b>closed </b></span><br />

                        </p>
                    </div>
                </div>

                    <div className='contact-1'>

                        <h3 className='heading-span text-theme'>
                            Contact Details

                        </h3>
                        <hr className='bg-light w-50 text-left mx-0 p-0 my-0'></hr>

                        <div className='my-2'>
                            <p className='text-secondary '>
                                <b>WhatsAppp :</b> 072 370 0825 <br />
                                <b>Calls :</b> 072 370 0825 <br />
                                <b>Email : </b>
                                <a className=" text-orange" href='mailto:info@prestigepoolcare.co.za'><span className='value mail-link1 '>info@prestigepoolcare.co.za</span>
                                </a> <br />

                            </p>
                        </div>
                    </div>

                    <div className='contact-1'>
                        <div className='contact-title'>
                            <h3 className='heading-span text-theme'>
                                Location
                            </h3>
                            <hr className='bg-light w-50 text-left mx-0 p-0 my-0'></hr>
                        </div>
                        <div className='contact-content'>
                            <p className='text-secondary'>
                                7 Galated rd <br/>
                             Bedworth Park <br/>
                           Vereeniging </p>
                        </div>
                    </div>
                    <div className='button-container d-flex flex-row justify-content-end'>
                    </div>
                </div>


            </div>
        </section>
    </>
}

export default FindUs;