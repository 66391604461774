import React from 'react';
import "./index.css";
import { Link } from 'react-router-dom';

function MenuItem(props) {


    if(!props.link){
           return (
        <>
            <div className="d-menu-item text-white mx-1 mt-2">
                <a href={props.href} style={{
                    textDecoration: 'none',

                }}>
                    <span className="hover text-white text-nowrap nav-link"> {props.title}

                    </span></a>
                <div className="underline text-white p-0 m-0" ></div>

            </div>

        </>
    )
    }
    else{
        return (
        <>
            <div className="d-menu-item text-white mx-1 mt-2">
                <Link to={props.link} style={{
                    textDecoration: 'none',

                }}>
                    <span className="hover text-white text-nowrap nav-link"> {props.title}

                    </span></Link>
                <div className="underline text-white p-0 m-0" ></div>

            </div>

        </>
    )

    }


};


export default function Dmenu(props) {

    return (
        <>
            <div className="d-flex flex-row p-hide">
                {props.items.map((item) => <MenuItem key={item.id} list={item.list} title={item.title} href={item.href} link={item.link}/>)}
            </div>

        </>
    )

}