import './index.scss'
import SectionTitle from "../../components/SectionTitle";
import Img1 from '../../assets/img/foundation.jpg'
import Img2 from '../../assets/img/Completed swimming pool.jpg'
import TitlesHeaders from "./Component2";


function OurWork(){
    return (
        <section id='Work'>
              <div className='container-fluid mx-0 my-4 py-0 clip-out curved'>
                <SectionTitle name={'Our Work'} />
              <TitlesHeaders/>


              </div>

    </section>
    )
}

export default OurWork;