import SectionTitle from '../../components/SectionTitle'
import './index.scss'
import NewComponent from "./NewComponent";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import S1 from '../../assets/img/s1.jpg'
import S2 from '../../assets/img/s2.png'
import S3 from '../../assets/img/s3.png'
import S4 from '../../assets/img/s4.png'
import S5 from '../../assets/img/s5.jpg'
import S6 from '../../assets/img/s6.png'
import S7 from '../../assets/img/s7.png'
import S8 from '../../assets/img/s8.png'
import S9 from '../../assets/img/s9.png'
import S10 from '../../assets/img/s10.png'
import T1 from '../../assets/img/s11.png'


const settings = {
    autoPlay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,

    swipeToSlide: true,


    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1
            }
        }

    ]
};


let image = S1;

const items = [

    {

        title: ' Solar Heater Installation ',
        image: S1
    },

    {

        title: 'Thatching Roof & Decking',
        image: T1
    },
    {

        title: ' Heat Pump Installation ',
        image: S2,
    },
    {
        title: 'Pool chemistry ',
        image: S3

    },
    {
        title: 'Pool installation ',
        image: S4,
    },
    {
        title: ' Pool Maintenance ',
        image: S5,
    },
    {
        title: 'Gunite/Concrete Pool',
        image: S6

    },
    {
        title: ' Fiber Glass Linings ',
        image: S7

    },
    {
        title: 'Fiber Glass Pool ',
        image: S8

    },
    {
        title: 'Pool Repairs',
        image: S9

    },
    {
        title: 'Pool Renovations ',
        image: S10

    },

]

function OurServices() {
    const [index, setIndex] = useState(0);
    let slideIndex = 0;

    function Dot(props) {
        let internalIndex = props.index === index ? 'active' : 'inactive';
        return <div className={`slider-control-dot ${internalIndex}`} index={props.index} onClick={() => { setIndex(props.index) }}></div>
    }


    useEffect(() => {
        const event1 = setInterval(() => {
            slideIndex++;
            if (slideIndex === items.length) {
                slideIndex = 0;
                setIndex(slideIndex); // This is to trigger new render when all sliders are rendered
            } else {
                setIndex(slideIndex) // Trigger new render when we still have next slide
            }
        }, 15000);

        return () => clearInterval(event1); // This ensures we don't have infinity loo

    }, []);


    return (
        <section id='Services'>
            <div className='container-fluid mx-0 my-4 py-0 clip-out p-relative '>
                <SectionTitle name={'Our services'} />
                <div className='container-fluid border rounded pb-4 pt-0 px-0 m-0 '  >

                    <div className={'container-fluid p-0 m-0 '}>
                        <Slider {...settings} >
                            {
                                items.map((item, index) => {
                                    return (
                                        <div key={index} className={' py-0 px-2 m-0'}>
                                            <NewComponent item={item} index={index} />
                                        </div>
                                    )
                                }
                                )
                            }

                        </Slider>

                    </div>

                    {/*<SlideControl/>*/}

                </div>


                <hr className='my-2 border primary' />
            </div>
        </section>
    )
}

export default OurServices;