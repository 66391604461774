import React from 'react';
import './index.scss';
import Footer from '../Footer';
import Home from '../Home';
import MissionVision from '../Mission';
import AboutUs from '../About';
import OurServices from '../Services';
import FindUs from '../FindUs';
import GetInTouch from '../GetInTouch';
import WhyChooseUs from "../WhyChooseUs";
import LocateUs from "../LocateUs";
import FAQs from '../FAQs';
import OurWork from "../OurWork";
import Services from "../OurServices";

function SectionGap() {
    return <div className='section-gap container-fluid mx-0 my-4 py-4 px-3 d-none d-sm-block'></div>
}

function Body() {

    return (
        <>
            <div className='container-fluid m-0 p-0'>

                <Home />

                <div className='group-1 container-fluid  p-0 m-0 '>
                       <div className='group-1 container-fluid  d-flex p-0 m-0 align-center'>
                <AboutUs />
                <MissionVision />
                       </div>
                      <hr className='my-4 border p-hide' />

                     </div>

                <SectionGap />
                <OurServices />

                {/*<Services/>*/}
                  <SectionGap/>
                <OurWork/>

                {/*<FAQs />*/}

                <div className='group-1 container-fluid  d-flex p-0 m-0'>
                    <FindUs />
                    <GetInTouch />
                </div>
                 <LocateUs/>
                <Footer />
            </div>
        </>
    )
}

export default Body