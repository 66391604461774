import React, { useState } from 'react';
import './index.css';

export default function WhatsappIcon(props) {
    return (
        <div className='m-0 p-0 whatsappIcon position-fixed hover p-0 m-0 d-flex justify-content-center align-items-center'>
            <div className="red-dot"></div>
            <a className='p-0 m-0 d-hide d-flex justify-content-center align-items-center' href={"whatsapp://send?text=Hi!&phone=" + props.phone} ><i className="fab fa-whatsapp d-flex justify-content-center align-items-center "></i></a>
            <a className='p-0 m-0 p-hide' href={"https://wa.me/" + props.phone} ><i className="fab fa-whatsapp d-flex justify-content-center align-items-center" /></a>

        </div>
    )


}