import React, { useEffect, useState } from 'react';
import './index.scss';
import Button from '../../components/ButtonPink';
import ButtonSquare from '../../components/ButtonSquare';

// import { APIurl,rootUrl,BaseAPIurl } from '../../App';
import axios from 'axios';
import { APIurl } from '../../App';

const BaseAPIurl = "https://prestigepoolcare.co.za";

function Videos(props) {
    const [caption1, setCaption] = useState("");
    const [shorten, setShorten] = useState(true);
    var text = "";
    var i = 0;
    while (text.length < 50) {

        if (i >= props.caption.length) {
            break;
        }
        else {
            text = text + props.caption[i];
            i++;
        }

    }

    const ReadMore = () => {
        setShorten(false);
        setCaption(props.caption);
    }

    useEffect(() => {


        if (props.caption.length < 50) {

            setShorten(false);

        }
        else {
            text = text + "...";
        }
        setCaption(text);

    }, []);

    return (
        <>
            <div className=" my-2 mx-2 rounded animate__animated  animate__zoomIn" >
                <video className='card-img-top' width="320" height="240" controls>
                    <source src={'https://prestigepoolcare.co.za/videos/' + props.video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

            </div>
        </>
    )
}


export default function Video() {

    const [videos, setVideos] = useState([]);
    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [logging, setLogging] = useState(false);
    const [isempty, setIsEmpty] = useState(false);
    const [diaglogueID, setdiaglogueID] = useState("msg16");

    useEffect(() => {

        //Get all Videos
        axios.post(APIurl, {
            cmd: "getallvideos"
        })
            .then((response) => {
                var res = response.data;
                console.log(res);
                if (!res["success"]) {
                    setLogingmsg(res["data"]);
                    setColors("Could not send request");
                }
                else {
                    console.log("Here", res["data"]);
                    setVideos(res["data"]);
                    if (res["data"].length == 0) {
                        setIsEmpty(true);
                    }

                }

            })
            .catch((error) => {

                console.log(error);

            });
    }, [])

    return (<>
        <div className='container-fluid m-0 px-1 bg-white pt-4'>

            <div className='container-fluid my-3 mx-0 d-flex justify-content-around pt-4'>
                <div className='h3 animate__animated animate__fadeInDown'>
                    <Button name="Videos" styles="h4" Icon="video" />
                </div>
            </div>


            <div className='container-fluid mx-0 my-3 p-0 text-center'>
                {/*<h4 className='animate__animated animate__fadeIn animate__slower'> Videos</h4>*/}
                <div className='container-fluid mx-0 my-2 p-0 d-flex flex-wrap justify-content-around manage-photos'>
                    {isempty ? <h3 className='text-danger'> No videos found</h3> : videos.map((slide, index) => <Videos key={index} id={index} title={`video ${index}`} caption={`video ${index}`} video={slide.img} />)}

                </div>
                <ButtonSquare name="More Videos" Icon="plus" />
            </div>

        </div>

    </>)

}
