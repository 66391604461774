import './index.scss'
import { useInView } from 'react-intersection-observer';
import React, { useEffect, useRef, useState, useMemo } from 'react';

function SectionTitle(props) {

    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    const [onScroll, setClassName] = React.useState('hidden');

    useEffect(() => {

        if (inView) {
            setClassName('show');
        } else {
            setClassName('hidden');
        }
    }, [inView]);
    return <>
        <div className={`container-fluid text-left mx-0 px-2 my-0 p-0 blurry ${onScroll} mid-animation
         `} ref={ref}>
             < hr className='line
             h-10 bg-p m-0 border-theme color-theme'/>
            <h3 className={'text-theme'}> <b>{props.name}</b> <span><i className={props.icon} /></span></h3>
        </div>
    </>
}

export default SectionTitle;