import './index.scss'
import SectionTitle from "../../components/SectionTitle";
import SubmitForm from "../../components/SubmitForm";
import React from "react";



export default function LocateUs(){

    const directions_url = 'https://goo.gl/maps/vNsXMQNjCZZfSyqNA'

    return (
        <section id={'location'}>
        <div className={'container-fluid m-0 '}>
        <SectionTitle name={'Locate Us'} />
        <div className={'container-fluid mx-auto d-flex justify-content-center '}>
        <div className={'card rounded border p-2 container-fluid my-1'}>


            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3564.061800138579!2d27.8850089!3d-26.7104726!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9458f8c39c91cb%3A0x9beaefeaec7c1a5e!2s7%20Galatea%20Rd%2C%20Bedworth%20Park%2C%20Vereeniging%2C%201939%2C%20South%20Africa!5e0!3m2!1sen!2sus!4v1679205395564!5m2!1sen!2sus"
                width={"100%"} height={450} style={{border: 0}} allowFullScreen="" loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"/>

        <div className={'container-fluid text-right mx-0 p-0 d-flex justify-content-center mt-2'}>

            <a href={directions_url} className={'text-decoration-none'}>
                         <SubmitForm name="Follow Directions" type='submit' Icon="arrow-right" />
                </a>
                    </div>
            </div>
        </div>


    </div>
            </section>)
}