import './index.scss'
import React from "react";

function Swipe(props) {
    //1s linear " +  props.idx  +" infinite
    let ani = "swipe 1.5s linear  " + props.idx + "s infinite normal none running ";

    const style = {
        animation: ani
    }
    return (
        <>
            <span className="swipe ml-2" style={style}>
                <i className="fa fa-angle-right"></i></span>
        </>
    )
}


function ServiceComponent(props) {
    var swipes = [{ id: 0 }, { id: 0.1 }, { id: 0.2 }, { id: 0.3 }, { id: 0.4 }, { id: 0.5 }, { id: 0.6 }, { id: 0.7 },
    { id: 0.8 }, { id: 0.9 }, { id: 1 }, { id: 1.1 }, { id: 1.2 }, { id: 1.3 }, { id: 1.4 }, { id: 1.5 }];
    return (
        <div className={'container-fluid px-0 py-2 m-0 '}>
            <div className={'text-left m-0 p-0'}>
                <b>{`${props.index + 1}/10`}  </b>

                <div className="container ml-0 mr-0 mb-2 p-0 text-left d-flex flex-direction-row">
                    {
                        swipes.map((swipes) => (
                            <Swipe key={swipes.id} idx={swipes.id} />
                        ))
                    }
                    <span className="swipe ml-2 delay-1-5" >Swipe</span>
                </div>

            </div>
            <div className={'bg-theme-gradient text-white px-0 py-2 mx-0 my-1 text-center shadow rounded border-dashed'}> <h4><b>{props.item.title}</b></h4> </div>
            <div className={'Container container-fluid bg-white cover p-0 m-0  p-relative square'}>
                <img className={'w-100 cover m-0 p-0'} src={props.item.image} alt={'...'} />
            </div>

        </div>
    )
}

export default ServiceComponent;