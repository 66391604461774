import './index.scss'

function WorkComponent(props) {

    const item = props.item;

    return (



        <div key={props.item.text} className="col-xl-4 col-lg-6 col-sm-12 container-work" data-aos="fade-down">
            <div className='item position-relative mb-3 hover-zoom text-center animate__animated animate__fadeInDown '>
                <img src={item.image} alt={item.text} className="w-100 work-img-height" />
                <p className='project-item-title'>{item.text}</p>
                <a className='project-item-anchor' href={item.image}>VIEW PROJECT</a>
            </div>
        </div>
    )

}

export default WorkComponent;