import './index.scss'
import { useState } from "react";
import WorkComponent from "../Component";
import w1 from '../../../assets/img/foundation.jpg'
import w2 from '../../../assets/img/Completed swimming pool.jpg'
import w3 from '../../../assets/img/2022-06-07 (5).jpg'
import w4 from '../../../assets/img/s9.png'
import w5 from '../../../assets/img/s10.png'
import w6 from '../../../assets/img/600 (1).png'
import w7 from '../../../assets/img/s2.png'
import w8 from '../../../assets/img/s3.png'
import t1 from '../../../assets/img/thatcth 1 (6).jpeg'
import t3 from '../../../assets/img/Decking (3).jpeg'
import t2 from '../../../assets/img/thatcth 1 (1).jpeg'

const Img2 = w1;
const items = [

    {
        title: 'Pool Installation',
        items:
            [
                {
                    text: ' Foundation of swimming pool',
                    image: w1
                },
                {
                    text: 'Complete swimming pool, built \n' +
                        'around pretoria, soshanguve',
                    image: w2
                }
            ]
    },
    {
        title: 'Thatch and Decking',
        items:
            [
                {
                    text: 'Thatching Roof',
                    image: t1
                },
                {
                    text: 'Thatching Roof \n' +
                        'around Gauteng',
                    image: t2
                },
                {
                    text: 'Complete Thatching and Decking \n' +
                        'around Gauteng',
                    image: t3
                }
            ]
    },
    {
        title: 'Pool Care/Cleaning',
        items:
            [
                {
                    text: 'Cleaning pool',
                    image: w3
                },
                {
                    text: 'Cleaning pool',
                    image: w4
                }
            ]
    },
    {
        title: 'Pool Renovation',
        items:
            [
                {
                    text: 'Renovating a Pool',
                    image: w5
                },
                {
                    text: 'Renovating Pool',
                    image: w6
                }
            ]
    },
    {
        title: 'Pool Maintenance',
        items:
            [
                {
                    text: 'Maintenance',
                    image: w7
                },
                {
                    text: 'Maintenance',
                    image: w8
                }
            ]
    },

]
const items2 = [
    'Pool Installation',
    'Pool Cleaning',
    'Pool Renovation',
    'Pool Maintenance',
]


function TitlesHeaders() {
    const [index, setIndex] = useState(0);

    function SmallerComponet(props) {

        let className = '';
        let textColor = '';
        const chnageColor = () => {

            //get all e

        }

        if (props.index === index) {
            className = 'bg-white';
            textColor = 'text-theme border-dashed-reverse';
        }
        else {
            className = 'bg-theme-gradient';
            textColor = 'text-white border-dashed';
        }

        return (
            <div className={`${className} ${textColor} px-2  pt-1 mx-2 my-1 text-center shadow rounded  no-wrap hover-zoom-1 fast-animation d-flex align-center justify-content-center`} onClick={() => { setIndex(props.index) }}> <h4><b>{props.title}</b></h4> </div>
        )
    }

    return (
        <>
            <div className=' border rounded d-flex py-2 my-2 overflow-hidden justify-content-right tt'  >
                {items.map((item, index) => <SmallerComponet key={index} index={index} title={item.title} />)}
            </div>
            <div className={'container-fluid mx-0 my-4 p-0 text-left '}>
                <b className={'text-left'}><h3>{`${index + 1}. Swimming ${items[index].title} Projects`}</h3></b>
            </div>

            <div className='container-fluid border rounded m-0 px-2 d-flex flex-wrap justify-content-around py-4'  >
                {items[index].items.map((item, index) => <WorkComponent key={index} item={item} />)}

            </div>

        </>

    )
}
export default TitlesHeaders;