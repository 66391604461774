import './index.scss'
import { useState } from 'react'
import { Link } from 'react-router-dom'
function SideNavMenuItem(props) {

    const closeBar = () => {

        document.getElementById("side-nav").style.display = "none";
    }


    return (



        <div className="side-nav-menu-item animate__animated animate__fadeInDown" style={{ animationDelay: props.item.delay + "s" }} onClick={closeBar}>
            <div className="title-part text-white m-0 p-0">
                <div className='d-flex position-relative top-0 flex-row px-2 py-1'> <span className='d-flex mx-3'><i className={props.item.icon}></i> </span>

                    {props.item.isRoute ? <Link to={props.item.link} className="text-white" > {props.item.name} </Link> :
                        <a href={props.item.link} className="text-white"> {props.item.name} </a>

                    }
                    {/* <Link to={props.item.link} className="text-white" > {props.item.name} </Link> */}

                </div>
            </div>
            <hr className='p-0 m-auto w-75 divider border' />


        </div>

    )

}

export default SideNavMenuItem