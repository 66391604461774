import React, { useState } from 'react'
import SectionTitle from '../../components/SectionTitle';
import './index.scss'
import Component from './Component'
import Image from '../../assets/img/ba.png'




const bodyText = "Whether you're looking for a your existing one, we can help. Our team will work with you to design and install a pool that meets your needs and fits your budget.";
function MissionVision() {
    return (
        <div className={'py-auto d-flex justify-content-center mt-3'}>

            <section id='OurWork' className={'container-fluid h-100 '}>
                <div className={'p-hide'}>
                     <SectionTitle name={'....'} icon={'fa fa-swimmer'}/>
                </div>


                <div className='container-fluid mx-0 my-1  px-0 m-comp d-hide'>
                    <div className='mission-component-wrapper d-flex'>
                        <div className="" >
                            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                            <img className="card-img-top" src={Image} alt="Card image cap"/>
                                <div className="card-body bg-theme text-left">
                                    <h5 className="card-title text-white text-left pr-4 my-3"><b>{bodyText}</b></h5>
                                    <div className={'d-flex flex-row justify-content-left '}>
                                        <a href="#Contact" className="btn bg-white text-black curved shadow w-auto hover-scale fast-animation hover-glow"> <b>Contact Us</b></a>
                                    </div>

                                </div>
                        </div>
                    </div>

                </div>
                <div className='container-fluid p-0 m-0 p-hide w-100 d-flex bg-yellow curved shadow w-auto hover-zoom-1 mid-animation'>
                     <div className='container-fluid mx-0 my-0  px-0  p-hide img-bg text-white '>
                         {/*<h1>Left</h1>*/}
                    </div>
                     <div className='container-fluid mx-0 my-0  px-0  p-hide'>
                         <div className="" >

                                <div className="card-body bg-theme text-left">
                                    <p className="card-title text-white text-left pr-4 my-3"><b>{bodyText}</b></p>
                                    <div className={'d-flex flex-row justify-content-left '}>
                                        <a href="#Contact" className="btn bg-white text-black  w-auto hover-scale fast-animation hover-glow"> <b>Contact Us</b></a>
                                    </div>

                                </div>
                        </div>
                    </div>
                    </div>

            </section>
        </div>
    )
}

export default MissionVision;