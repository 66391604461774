import logo from './logo.svg';
import './App.css';
import Layout from './containers/LayOut';
import './common/styles/index.scss';
// import 'animate.css';
import { animateOnScroll } from './components/ViewPort';


export const APIurl = "https://prestigepoolcare.co.za/API.php";
export const BaseAPIurl = "https://prestigepoolcare.co.za/";

// export const APIurl = "http://localhost:81/sydneys_api/API.php";
// export const BaseAPIurl = "http://localhost:81/sydneys_api/";


//eslint-disable-next-line no-restricted-globals 
var BaseURL = location.href;
BaseURL = BaseURL.split("/");
BaseURL = BaseURL[0] + BaseURL[1] + "//" + BaseURL[2];
export const rootUrl = BaseURL;


function App() {

  return (
    <div className="App m-0 p-0">
      <Layout />
    </div>
  );
}

export default App;
