import SectionTitle from '../../components/SectionTitle'
import './index.scss'
import { useInView } from 'react-intersection-observer';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useRive, useStateMachineInput } from 'rive-react';




function AboutUs() {

        const { rive, RiveComponent } = useRive({
        src: 'wwd.riv',
        autoplay: true,
        stateMachines: 'State Machine 1',

    })


    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    const [onScroll, setClassName] = React.useState('hidden');

    useEffect(() => {

        if (inView) {
            setClassName('show');
        } else {
            setClassName('hidden');
        }
    }, [inView]);

    return <div className={'container-fluid mx-0 px-0 pt-4 pb-1 about-us overlap-top '}>
        <section className={`container-fluid mx-0 py-0 ${onScroll} blurry slow-animation `} id='AboutUs' ref={ref}>

            <SectionTitle name={'About Us'} />

            <div className={'container-fluid mx-0 py-0 d-flex'}>

            <div className='card about-wrapper d-relative text-left  p-3 left-border container-fluid w-100' >

                <p>
                    At PPC ( Prestige Pool Care ), we pride ourselves on providing top-quality services for all your swimming pool needs. Whether you're looking for routine cleaning and maintenance or a complete installation, our team of experienced professionals is here to help.
                    </p>
                <p>
                    We understand that a swimming pool is an investment in your property and your quality of life. That's why we are committed to delivering exceptional customer service and expert care to ensure that your pool is always clean, safe, and ready to use.
                </p>

            </div>


            {/*    /!*<div className={'container-fluid p-hide '}>*!/*/}

            {/*    /!*    <a  href={'#Services'}>*!/*/}
            {/*    /!*    <RiveComponent width={'100%'}/>*!/*/}
            {/*    /!*        </a>*!/*/}
            {/*    /!*</div>*!/*/}

                   </div>

            <hr className='my-4 border primary d-hide' />


        </section>
    </div>
}

export default AboutUs