import './index.scss'
import PageHeader from "../../components/PageHeader";
import MiniFooter from "../MiniFooter";
import ImageGallery from 'react-image-gallery';
import React, { useEffect, useState } from "react";
import MSGDialogue from '../../components/Dialogue';
import axios from 'axios';

// import { BaseAPIurl,rootUrl } from '../../App';
import Footer from "../Footer";
import { APIurl } from '../../App';



const BaseAPIurl = "https://prestigepoolcare.co.za";


export default function Gallery() {

  const [photos, setPhotos] = useState([]);
  const [limit, setLimit] = useState(4);
  const [isEmpty, setEmpty] = useState("No Images");
  const [loginmsg, setLogingmsg] = useState("");
  const [colors, setColors] = useState("");
  const [logging, setLogging] = useState(false);
  const [diaglogueID, setdiaglogueID] = useState("msg16");

  useEffect(() => {


    let el1 = document.getElementById("header1");
    let logo = document.getElementById("logonow");
    let calls = document.getElementById("calls");
    let book = document.getElementById("booknow");
    let icons = [document.getElementById("scroll1"), document.getElementById("scroll2")];
    el1.classList.remove("in-viewport");

    el1.classList.add("out-viewport");
    calls.classList.remove("d-flex");
    el1.classList.add("animate__fadeInDown");
    calls.style.display = "none";
    icons[0].style.display = "none";
    icons[1].style.display = "block";
    book.style.display = "block";
    logo.style.display = "block";


    //Get all photos
    setLogingmsg("Getting Photos ...... ");
    setColors(" text-white bg-secondary");
    try {
      document.getElementById(diaglogueID).style.display = "block";
    } catch (error) {
      console.log("element is not found");
    }
    setPhotos([]);
    axios.post(APIurl, {
      cmd: "getallphotos"
    })
      .then((response) => {

        var res = response.data;





        if (!res["success"]) {

          setLogingmsg(res["data"]);
          setColors("Could not send request");
          setEmpty("No photo found");
        }
        else {

          if (res["data"].length == 0) {

            setEmpty("No photo found");
          }
          else {
            setEmpty("");
          }


          const images = [];



          res["data"].forEach(element => {

            let el = {
              original: BaseAPIurl + "/gallery/" + element.img,
              thumbnail: BaseAPIurl + "/gallery/" + element.img
            };
            images.push(el);

          });
          setPhotos(images);
          document.getElementById(diaglogueID).style.display = "none";
        }

      })
      .catch((error) => {

        console.log(error);

      });
  }, []);
  return (
    <div className={'make-stick vancacies'}>
      <div>
        <div className={'container-fluid mx-0 p-0 my-2'}>
          <PageHeader name={'Gallery'} />
          <h3 className='text-danger my-1'>{isEmpty}</h3>
          <div className={'container-fluid w-100 my-2'}>
            <ImageGallery items={photos} autoPlay={true} />
          </div>
        </div>
      </div>

      <Footer />

    </div>
  )
}