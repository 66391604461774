import './index.scss'
import './waves.scss'
import React from "react";
import BG from '../../assets/img/homebg.jpg'
import Logo from '../../assets/img/logo.png'

const items = [
    { value: 'Pool Installation', delay: 0.1 },
    { value: 'Pool Cleaning', delay: 0.2 },
    { value: 'Pool Renovation', delay: 0.4 },
    { value: 'Pool Maintenance', delay: 0.6 },
]

function ListItem(props) {
    return (
        <div className={'text-left home-text animate__animated animate__fadeInUp'} style={{ animationDelay: props.value.delay + 's' }}>
            <b><span> <i className={'fa fa-check-circle text-red no-wrap'} /> </span>{props.value.value}</b>
        </div>
    )
}

function Home() {


    return <>
        <section id='home' className={'container-fluid m-0 p-0 slider-wrapper '} >
            <div className={'container-fluid bg-theme text-white text-center h-100 p-0 m-0'}>
                <div className={'top-home h-85 bg-yellow'}>
                    < div id="home-tracker" className={'top-top container-fluid p-0 m-0 h-60 text-white text-center p-relative d-flex flex-column justify-content-center'}>
                        <h1 className='animate__animated animate__fadeInDown animate__slower'>Prestige Pool Care</h1>
                        <b><h3 className='animate__animated animate__fadeInUp animate__slow'>
                            #Number 1 swimming pool cleaners & Builders in Gauteng
                        </h3>
                        </b>

                        <div className={'wave'}>
                            <svg className="waves" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                                <defs>
                                    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                                </defs>
                                <g className="parallax">
                                    <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                                    <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                    <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                                </g>
                            </svg>
                        </div>
                        {/*<img className={'container ml-auto w-auto logo-wrapper d-flex justify-content-end'} src={Logo} alt={'...'}/>*/}
                        <div className={' px-1 py-0 m-0 d-flex justify-content-end logo-wrapper '}>

                            <img className={'img-fluid animate__animated animate__zoomIn animate__slower'} src={Logo} alt={'...'} />

                        </div>
                    </div>
                    < div className={' top-bottom container-fluid p-0 m-0 h-40 bg-white  p-relative text-theme pl-2'}>
                        <h2 className={'text-left text-black  animate__animated animate__fadeInDown animate__slower'}>Do you need?</h2>

                        <div className={'container-fluid p-0 m-0 d-flex'}>
                            <div className={'container-fluid d-flex flex-column align-center justify-content-start text-start h-auto my-auto '}>
                                {
                                    items.map((item, index) => {
                                        return <ListItem key={index} value={item} />
                                    }
                                    )
                                }

                            </div>
                            <div className={'text-center container-fluid'}>
                                <div className={'d-flex flex-column justify-content-around '}>
                                    <i className={'fa fa-location-dot myIcon1 text-red text-center m-2'} />

                                    <div className={'shadow circle cover mx-auto square-40  location animate__animated animate__zoomIn animate__slower'} >

                                    </div>

                                    <a href={'#location'} className={'text-decoration-none'}>
                                        <div className={' fsn bg-yellow mx-auto shadow rounded mt-1 py-2 text-black m-0 px-1  animate__animated animate__fadeInUp animate__slow  w-60 no-wrap'}>
                                            <b className={'no-wrap'}>
                                                Find us now
                                            </b>

                                        </div>
                                    </a>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div className={'bottom-home h-15 bg-theme text-center d-flex align-center'}>
                    <a href="https://wa.me/+27723700825" className={'hover-zoom fast-animation btn bg-black text-white w-auto mx-auto h-auto shadow rounded my-auto'}>  <b>Request Quote</b></a>
                </div>
            </div>
        </section>
    </>
}

export default Home