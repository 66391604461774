import React from 'react';
import './index.scss';


const Footer = () => {
    return (
        <div id='footer' className='container-fluid py-3 position-relative m-0 bg-theme'>

            <div className='container-fluid position-relative p-0 m-p'>

                <div className='p-0 container-fluid main-wrapper d-flex justify-content-around m-0'>
                    <div className='left container-fluid m-0 p-0'>
                        <h5 className='text-white text-yellow' >Social Links</h5>
                        <hr className={'text-yellow bg-yellow p-0 w-90'}/>
                        <ul className='list-unstyled d-flex justify-content-center'>
                            <li className={'my-icons-container'}><a href="https://www.facebook.com/profile.php?id=100079286491368"><i className='fab fa-facebook-f fa-fw my-icon'></i></a></li>
                            <li className={'my-icons-container'}><a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ"><i className='fab fa-twitter fa-fw my-icon'></i></a></li>
                            <li className={'my-icons-container'}><a href="https://wa.me/+27723700825"><i className='fab fa-whatsapp fa-fw my-icon'></i></a></li>
                            <li className={'my-icons-container'}><a href="tel:+27723700825"><i className='fas fa-phone fa-fw my-icon'></i></a></li>
                        </ul>
                    </div>
                </div>

                      <h5 className='text-white text-yellow' >Short Links</h5>
                   <hr className={'text-yellow bg-yellow w-90 p-0'}/>

                <ul className='short-links-list list-unstyled d-flex justify-content-center footer-navbar text-white'>
                    <li><a href="#home" className='short-links no-wrap text-decoration-none'>Home</a></li>
                    <li><a href="#OurWork" className='short-links no-wrap'>About Us</a></li>
                    <li><a href="#Services" className='short-links no-wrap'>Gallery</a></li>
                    <li><a href="#Contact" className='short-links no-wrap'>Contact Us</a></li>
                </ul>

                <div className={'container-fluid mx-0 my-2 p-0 w100' }>

                     <div className='container-fluid p-0 d-flex justify-content-center subscribe mt-4'>
                    <div>
                        <i className='fas fa-envelope'></i>
                    </div>
                    <div>
                        <input type="text" placeholder='Enter your email here...' name='Subscribeemail' />
                    </div>
                    <div className={''}>
                        <a href="Subscribe">Subscribe</a>
                    </div>
                </div>
                </div>

                 {/*<div className='right text-left px-4'>*/}
                 {/*       <h6 className='text-yellow'>Sydney's NA is registered under</h6>*/}
                 {/*    <p className='text-white '> <span className={'text-yellow'}>{'CIPC no'} </span>{':  2022/828276/07'}</p>*/}

                 {/*   </div>*/}

                <div className={'d-flex justify-content-center'}>
                    <p className={'text-left'}>
                       Copyright @ 2023 All rights reserved - <br/>Prestige Pool Care
                    </p>
                </div>


            </div>

        </div>
    )
}

export default Footer