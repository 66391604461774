import React from 'react';
import './index.scss';
import WhatsappIcon from '../../components/WhatsappIcon';
import Logo from '../../assets/img/logo.png'
import ScrollIcon from '../../components/ScrollIcon';
import { Link } from 'react-router-dom';

import Dmenu from '../../components/Dmenu';

const listmenu = [{ id: 1, title: "Home", href: '#home', link: '/home' }, { id: 2, title: "About Us", href: '#AboutUs' },
{ id: 3, title: "Services", href: '#Services' }, { id: 4, title: "Contact Us", href: '#Contact' },
{ id: 5, title: "Gallery", href: '#home', link: '/gallery' }, { id: 6, title: "Videos", href: '#home', link: '/videos' }
];

export default function Header() {

    const openBar = () => {
        document.getElementById("side-nav").style.display = "block";
    }

    return (
        <>
            <div className="header p-0 m-0 container-fluid in-viewport" id="header1">
                <div className="navBar d-flex flex-row justify-content-between m-0 py-0 px-1" >
                    <div className="d-hide ml-3 menu-bars"><i className="fa fa-bars text-white m-1" onClick={openBar}></i></div>
                    <div id="logonow" className="header-logo text-left animate__animated animate__fadeInDown"><img src={Logo} alt="..." /></div>
                    <a id="booknow" className="book-now rounded-pill shadow bg-white text-dark px-4 py-1 mx-3 animate__animated animate__fadeInDown mt-2" href="#Contact" ><b>Contact Us</b></a>
                    <div id="logonow" className="header-logo text-left animate__animated animate__fadeInDown"><img src={Logo} alt="..." /></div>
                    <div id="calls" className={" container-fluid my-1 py-1 px-0 m-0 text-center d-flex  justify-content-between animate__animated animate__fadeInDown"}>

                        <div className={'m-auto'}>
                            <b className={'text-yellow text-center no-wrap'}>
                                <h3>{'+27 723 700 825'}</h3>
                            </b>
                        </div>


                        <a href={'tel:+27723700825'}>
                            <div className={'border-none btn bg-theme-gradient-dark border-none text-white h-auto my-auto curved-1 w-auto hover-zoom-1 fast-animation'}>
                                <b>Call us now</b>
                            </div>

                        </a>


                    </div>


                    <Dmenu items={listmenu} />

                </div>
                <div className="w-icon">
                    <WhatsappIcon phone="+27723700825" />
                </div>

                <div className="s-icon" id="scroll1">
                    <ScrollIcon href='#footer' icon='arrow-down' id='1' />
                </div>
                <div className="s-icon" id="scroll2">
                    <ScrollIcon href='#home' icon='arrow-up' id='2' />
                </div>
            </div>
        </>
    )

}

