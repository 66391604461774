import { useEffect } from 'react';
import SideNavMenuItem from './Component';
import './index.scss'

const closeBar = () => {

    document.getElementById("side-nav").style.display = "none";
}
const items = [
    {
        name: "Home",
        icon: "fa fa-home",
        link: "/home",
        delay: 0.1,
        closeBar: closeBar,
        hasSubmenu: false,
        isRoute: true,
        submenu: []
    },
     {
        name: "Contact Us",
        closeBar: "",
        delay: 0.2,
        icon: "fa fa-phone-volume",
        link: "/Home#Contact",
        hasSubmenu: false,
        isRoute: false,
        submenu: [
        ]

    },

    {
        name: "About Us",
        icon: "fa fa-user",
        closeBar: "",
        link: "/Home#AboutUs",
        delay: 0.3,
        isRoute: false,
        hasSubmenu: false,
        submenu: [


        ]

    },
    {
        name: "Our Services",
        icon: "fa fa-swimmer",
        closeBar: "",
        delay: 0.4,
        link: "/Home#Services",
        hasSubmenu: false,
        isRoute: false,
        submenu: [

        ]

    },
       {
        name: "Our Gallery",
        icon: "fa fa-image",
        closeBar: "",
        link: "/gallery",
        isRoute: true,
        delay: 0.5,
        hasSubmenu: false,
        submenu: [

        ]

    },
           {
        name: "Videos",
        icon: "fa fa-video",
        closeBar: "",
        link: "/videos",
        isRoute: true,
        delay: 0.5,
        hasSubmenu: false,
        submenu: [

        ]

    },
    {
        name: "Our Work",
        icon: "fa fa-user",
        closeBar: "",
        link: "#Work",
        delay: 0.6,
        hasSubmenu: false,
        submenu: [


        ]

    },



]

function SideNav() {
    useEffect(() => {

    }, []);

    return (
        <div className="side-nav animate__animated animate__fadeInLeft" id="side-nav">

            <div className='d-flex justify-content-end py-0 px-2 m-0'>
                <span className="close-btn m-0 p-0"><i className="fas fa-times text-danger h1" onClick={closeBar} ></i>

                </span>
            </div>

            <div className='body-wrapper mt-4'>
                <div className="side-nav-body">
                    {items.map((item, index) =>
                        <SideNavMenuItem item={item} key={index} />
                    )}
                </div>
                <div className='conatiner-fluid w-100 mx-0 side-nav-footer position-absolute bottom-0 left-0 px-3'>

                    <div className='socials container-fluid  mx-0 my-1 animate__animated animate__fadeInUp text-left mt-4  pt-4' style={{ animationDelay: "0.6s" }}>
                        <a href='mailto:info@prestigepoolcare.co.za'>
                            <h4 className='text-secondary text-yellow '><i className='icon-nav fas fa-envelope mr-4'></i>
                                info@prestigepoolcare.co.za
                            </h4>
                        </a>

                        <a href='https://wa.me/+27723700825'> <h4 className='text-secondary text-yellow'><i className='icon-nav fab fa-whatsapp mr-4 '></i>
                            072 370 0825
                        </h4></a>

                    </div>

                </div>
            </div>


        </div>
    )

}

export default SideNav;