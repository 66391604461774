import React from 'react'
import Header from '../Header'
import SideNav from '../SideNav'
import { Route, Routes, Outlet, BrowserRouter } from 'react-router-dom'
import Body from '../Body'
import NoPage from '../NoPage'
import Gallery from "../Gallery";
import Video from "../Videos";


function Layout() {

    return (
        <>
            <BrowserRouter>
            <Header/>
            <SideNav/>
                <Routes>
                    <Route index element={<Body />}/>
                    <Route path="/" element={<Body />}/>
                    <Route path="/home" element={<Body />}/>
                    <Route path={'/gallery'} element={<Gallery/>}/>
                    <Route path={'/videos'} element={<Video/>}/>
                    <Route path="*" element={<NoPage />}/>
                </Routes>
            </BrowserRouter>
            <Outlet />
        </>
    );
}


export default Layout