import React, { useState } from 'react';
import './index.css';

export default function ScrollIcon(props) {
    return (
        <div className='m-0 p-0 scrollIcon hover p-0 m-0' id={props.id}>
            <a className='p-0 m-0' href={props.href} ><i className={"fa fa-" + props.icon}></i> </a>
        </div>
    )


}

