import SectionTitle from '../../components/SectionTitle';
import './index.scss';
import SubmitForm from '../../components/SubmitForm';
import MSGDialogue from '../../components/Dialogue';
import { APIurl, rootUrl, BaseAPIurl } from '../../App';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';



function GetInTouch() {

    //!onscroll animation
    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    const [onScroll, setClassName] = React.useState('hidden');

    useEffect(() => {

        if (inView) {
            setClassName('show');
        }
        // else {
        //     setClassName('hidden');
        // }
    }, [inView]);

    //!onscroll animation



    const [loginmsg, setLogingmsg] = useState("");
    const [colors, setColors] = useState("");
    const [diaglogueID, setdiaglogueID] = useState("msg10");
    const [logging, setLogging] = useState(false);


    const process = (event) => {
        event.preventDefault();
        setLogingmsg("Sending Messaging...... ");
        setColors(" text-white bg-secondary");
        setLogging(true);
        try {
            document.getElementById(diaglogueID).style.display = "block";
        } catch (error) {
            console.log("element is not found");
        }

        let name = document.getElementById("name").value;
        let email = document.getElementById("email").value;
        let phone = document.getElementById("phone").value;
        let message = document.getElementById("message").value;

        var ok = 1;

        if (ok == 1) {
            axios.post(APIurl, {
                cmd:"sendmessage",
                name: name,
                email: email,
                phone: phone,
                message: message
            })
                .then((response) => {

                    console.log(response.data);
                    document.getElementById("register_form").reset();
                    var res = response.data;
                    if (!res["success"]) {
                        setLogingmsg(res["data"]);
                        setColors(" text-white bg-danger");
                    }
                    else {
                        setLogingmsg(res["data"]);
                        setColors(" text-white bg-success");

                    }
                    setLogging(true);
                    document.getElementById(diaglogueID).style.display = "block";
                    // eslint-disable-next-line no-restricted-globals
                    //location.href = 'http://localhost:81/softrans/API.php';
                    // Handle data
                })
                .catch((error) => {
                    console.log(error);
                })
        }

    }


    return (
        <>
            {logging ? <MSGDialogue msg={loginmsg} id={diaglogueID} colors={colors} /> : ""}
            <div className={`container-fluid my-3 mx-0 px-3 py-3 down-off-screen mid-animation blurry ${onScroll} delayed-animation`} ref={ref}>
                <SectionTitle name={"Get In Touch"} />
                <form className='form-group p-2 rounded border form-container  the-form' method='post' id='register_form' onSubmit={process} >

                    <div className='form-group my-2 py-1 text-secondary'>
                        <h4 className=' text-center mt-0 mb-2'> Fill the form below</h4>
                        <hr className='bg-light w-90 mx-auto' />

                        <div className='form-group p-0 m-1'>
                            <input className='form-control' type="text" id='name' placeholder='Enter full names*' required />
                        </div>

                        <div className='form-group p-0 m-1' >
                            <input className='form-control' text="email" id='email' placeholder='Email Address' required />
                        </div>

                        <div className='form-group p-0 m-1' >
                            <input className='form-control' text="text" id="phone" placeholder='Cellphone Number' required />
                        </div>

                        <div className='msg-text text-black'>
                            <b>Message</b>
                        </div>

                        <div className='form-group p-1'>
                            <textarea className='form-control' text="text" id='message' placeholder='Type your message here...' required></textarea>
                        </div>

                    </div>

                    <div className={'container-fluid text-right m-0 p-0 d-flex justify-content-end'}>
                         <SubmitForm name="Send Message" type='submit' Icon="arrow-right" />

                    </div>


                </form>

            </div>
        </>

    )







}

export default GetInTouch;