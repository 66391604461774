import React from 'react';
import "./index.css";
import { Link } from "react-router-dom";


export default function Menu(props) {
    return (
        <>

            <div className={"btn bg-theme p-1 rounded text-white text-center" + props.parentStyle}>
                <div className="inner-layer rounded px-3 text-center text-white hover">
                    <Link to={props.href} className={'text-center text-white m-0 p-0 h3'}> <b>{props.name}</b> <i className={"fa fa-" + props.Icon + " mx-2 "} ></i></Link>
                </div>

            </div>
        </>
    )

}