import './index.scss'
import { useInView } from 'react-intersection-observer';
import React, { useEffect } from 'react';


function MisionComponent(props) {

    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    const [onScroll, setOnScroll] = React.useState('hidden');

    useEffect(() => {

        if (inView) {
            setOnScroll('show');
        }
    }, [inView]);

    return (
        <>
            {/*<div className={` mx-3 w-auto my-3 py-3 border rounded shadow ${onScroll} down-off-screen mid-animation card`} ref={ref} >*/}
            {/*    <div className={'card-body bg-pink'}>*/}
            {/*        <h2><b>{props.item.title}</b></h2>*/}
            {/*    </div>*/}
            {/*    <div className={'card-img-bottom'}>*/}
            {/*        <img src={props.item.image} className={'card-img-top'} alt={'...'}/>*/}
            {/*    </div>*/}
            {/*    <div className={'card-img-overlay'}>*/}
            {/*          <p>*/}
            {/*                {props.item.description}*/}
            {/*        </p>*/}
            {/*    </div>*/}
            
            
            {/*</div>*/}


            <div className={`left-off-screen card rounded text-center shadow my-3 mx-2 hover-overlay smaller-card-2 rounded curved-1 mid-animation ${onScroll} m-comp`} ref={ref}>

                <img className={'card-img-bottom'} src={props.item.image} alt={'...'}/>
                <div className={'card-img-overlay container-fluid p-0 m-0 bg-op m-comp mid-animation'}>

                   <div className={'card-body bg-p text-white py-1'}>
                    <h3 className={'card-title '}>
                        {
                            props.item.title
                        }
                    </h3>
                </div >

                    <div className={'container-fluid p-0 m-0 d-flex justify-content-center align-content-center'}>  <p className={'text-white text-left p-3'}>
                        {props.item.description}
                    </p>
                    </div>


                </div>

            </div>

        </>
    )
}

export default MisionComponent;